/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://0kt1ylhah0.execute-api.us-east-2.amazonaws.com/sisecnewqa",
            "region": "us-east-2"
        },
        {
            "name": "getStudies",
            "endpoint": "https://rx4rzzief7.execute-api.us-east-2.amazonaws.com/sisecnewqa",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://dhjgnrurgjbnnfwmclarw3u2wy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:ef67e0f8-c9cc-432e-883d-dcd5764a26a9",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_IjoMNScbX",
    "aws_user_pools_web_client_id": "4i995r33vekk2s6itgf0jf8t2s",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sisechelpfiles95332-sisecnewqa",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
